.wrapper {
  width: 100%;
  border-radius: 20px;
  border: 1px solid #d4d8d9;
  background: #fff;
  color: #111;
  padding: 40px 40.5px;
  margin-bottom: 40px;
  position: relative;
  z-index: 10;

  @media screen and (min-width: 600px) {
    // max-width: 265px;
    padding: 40.85px 39px;
    max-height: 368px;
  }

  @media screen and (min-width: 960px) {
    padding: 40.85px 41px 41px;
  }

  @media screen and (min-width: 1260px) {
    margin-bottom: 0px;
    height: 360px;
    max-height: none;
  }

  img {
    display: block;
    width: fit-content;
    margin: 0 auto 21px;

    @media screen and (min-width: 600px) {
      margin-bottom: 13.35px;
    }
  }

  a {
    color: #096ffa;
    font-size: 16px;
    font-weight: 900;
    line-height: 26px;
  }
}

.content {
  font-size: 16px;
  line-height: 31px;
  margin-bottom: 21px;

  @media screen and (max-width: 1360px) {
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 27px;
  }

  @media screen and (max-width: 1075px) {
    font-size: 14px;
    line-height: 24px;
  }

  @media screen and (max-width: 960px) {
    font-size: 16px;
    line-height: 20px;
  }

  &.variantText {
    font-weight: 700;
  }
}

.author {
  @media screen and (max-width: 1400px) {
    margin-top: 0px;
    font-size: 15px;
  }
  @media screen and (max-width: 1260px) {
    font-size: 14px;
  }
  @media screen and (max-width: 1090px) {
    font-size: 14px;
  }
  @media screen and (max-width: 990px) {
    font-size: 14px;
  }
  @media screen and (max-width: 599px) {
    font-size: 16px;
  }
}
