.error-screen {
  main {
    position: relative;
    height: 30vh;
    z-index: 3;

    .message {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      max-width: 450px;
      margin: 60px auto 0;
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    h2 {
      padding: 0;
      margin: 0;
      font-size: 38px;
      line-height: 1.3;
      color: #111;
    }

    h3 {
      color: #111;
      font-weight: 400;
      padding: 0;
      margin: 0;
    }

    p {
      color: #111;
      &:first-child {
        font-weight: bold;
      }
    }

    .divider {
      height: 100px;
      width: 1px;
      background-color: #096ffa;
      @media screen and (max-width: 600px) {
        margin-top: 20px;
        margin-bottom: 10px;
        height: 1px;
        width: 90%;
      }
    }
  }
}
