.photo-collage.desktop {
  display: none;

  @media screen and (min-width: 600px) {
    display: flex;
    justify-content: center;
    margin-top: 17px;
    position: relative;
    z-index: 1;
    right: 29px;
    height: 389px;
  }

  @media screen and (min-width: 960px) {
    margin-top: 44px;
    right: 23px;
    height: 429px;
  }

  @media screen and (min-width: 1260px) {
    right: -50px;
    height: 511px;
  }

  .photo-collage__col-1,
  .photo-collage__col-2,
  .photo-collage__col-3 {
    display: inline-block;
  }

  img {
    height: 100%;
  }

  .photo-collage__col-1 {
    position: relative;
    top: 59px;
    left: 58px;

    @media screen and (min-width: 1260px) {
      left: 71px;
    }
  }

  .photo-collage__col-2 {
    position: relative;
    top: 36px;
    left: 58px;

    @media screen and (min-width: 1260px) {
      left: 59px;
    }
  }

  .photo-collage__col-1 div:nth-child(1) {
    width: 122px;
    height: 122px;
    margin-bottom: 15px;
    position: relative;
    left: 40px;

    @media screen and (min-width: 960px) {
      width: 129px;
      height: 129px;
      margin-bottom: 16px;
    }

    @media screen and (min-width: 1260px) {
      width: 160px;
      height: 160px;
      margin-bottom: 20px;
      left: 58px;
    }
  }

  .photo-collage__col-1 div:nth-child(2) {
    width: 218px;
    height: 145px;
    margin-right: 15px;

    @media screen and (min-width: 960px) {
      width: 231px;
      height: 153px;
      margin-right: 16px;
    }

    @media screen and (min-width: 1260px) {
      width: 286px;
      height: 190px;
      margin-right: 20px;
    }
  }

  .photo-collage__col-2 div:nth-child(1) {
    width: 218px;
    height: 145px;
    position: relative;
    right: 57px;
    margin-bottom: 15px;

    @media screen and (min-width: 960px) {
      width: 231px;
      height: 153px;
      margin-bottom: 16px;
    }

    @media screen and (min-width: 1260px) {
      width: 286px;
      height: 190px;
      margin-bottom: 20px;
    }
  }

  .photo-collage__col-2 div:nth-child(2) {
    width: 161px;
    height: 242px;

    @media screen and (min-width: 960px) {
      width: 170px;
      height: 257px;
    }

    @media screen and (min-width: 1260px) {
      width: 211px;
      height: 318px;
      right: -11px;
    }
  }

  .photo-collage__col-3 div:nth-child(1) {
    width: 161px;
    height: 242px;
    margin-left: 15px;
    margin-bottom: 16px;

    @media screen and (min-width: 960px) {
      width: 170px;
      height: 257px;
    }

    @media screen and (min-width: 1260px) {
      width: 211px;
      height: 318px;
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }

  .photo-collage__col-3 div:nth-child(2) {
    width: 122px;
    height: 122px;
    position: relative;
    left: 15px;

    @media screen and (min-width: 960px) {
      width: 129px;
      height: 129px;
    }

    @media screen and (min-width: 1260px) {
      width: 160px;
      height: 160px;
    }
  }
}

.photo-collage.mobile {
  height: 284px;
  display: flex;
  justify-content: center;
  margin-top: 6px;
  position: relative;
  z-index: 1;

  @media screen and (min-width: 600px) {
    display: none;
  }

  img {
    height: 100%;
  }

  .photo-collage__col-1,
  .photo-collage__col-2 {
    display: inline-block;
  }

  .photo-collage__col-1 {
    position: relative;
    top: 40px;
    margin-right: 13px;
  }

  .photo-collage__col-1 div:nth-child(1) {
    width: 186px;
    height: 124px;
    margin-bottom: 14px;
  }

  .photo-collage__col-1 div:nth-child(2) {
    left: 49px;
    width: 137px;
    height: 210px;
  }

  .photo-collage__col-2 div:nth-child(1) {
    width: 137px;
    height: 210px;
    margin-bottom: 12px;
  }

  .photo-collage__col-2 div:nth-child(2) {
    width: 99px;
    height: 100px;
  }
}
