.photo-changer__container {
  position: relative;
  overflow: hidden;
  border-radius: 20px; /* regular */
}

.photo-changer__img {
  position: absolute;
  -moz-border-radius: 20px; /* for Firefox */
  -webkit-border-radius: 20px; /* for Webkit-Browsers */
}

.photo-changer__img__next {
  animation-name: fadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 7s;
  animation-direction: alternate;

  @keyframes fadeInOut {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}
