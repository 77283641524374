.container {
  padding: 50px 0 65px;

  @media screen and (min-width: 780px) {
    padding: 65px 0 80px;
  }

  @media screen and (min-width: 1140px) {
    padding-top: 80px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-left: calc((100vw - 89.3vw) / 2);
  }

  @media screen and (min-width: 1440px) {
    max-width: 1230px;
    margin: 0 auto;
  }
}

.textAndNavWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 45.78px;

  @media screen and (min-width: 780px) {
    width: 74%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 auto 50px;
  }

  @media screen and (min-width: 1140px) {
    flex-direction: column;
    margin: 0;
    margin-right: 103px;
    margin-top: 30px;
    width: 276px;
  }
}

.textWrapper {
  width: 89.3%;
  margin: 0 auto 26px;

  h2 {
    font-size: 32px;
    font-weight: 900;
    line-height: 38px;
    color: #111;
    margin: 0 0 16px;
  }

  p {
    font-size: 16px;
    line-height: 26px;
    color: #111;

    @media screen and (min-width: 780px) {
      width: 420px;
    }

    @media screen and (min-width: 1140px) {
      width: auto;
    }
  }
}

.navWrapper {
  width: 89.3%;
  margin: 0 auto;

  @media screen and (min-width: 780px) {
    text-align: center;
  }

  @media screen and (min-width: 1140px) {
    text-align: left;
  }
}

.carouselContainer {
  position: relative;
  margin-left: calc((100vw - 89.3vw) / 2);

  @media screen and (min-width: 1140px) {
    margin-left: 0;
    width: 70%;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    display: block;
    width: 88px;
    height: 500px;
    background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  }

  @media screen and (min-width: 1440px) {
    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      display: block;
      width: 88px;
      height: 500px;
      background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    }
  }
}

.contentWrapper {
  overflow: hidden;
}

.content {
  display: flex;
}

.content > * {
  flex-shrink: 0;
  flex-grow: 1;
}
