.roundedLink {
  background-color: #111;
  color: #fff;
  font-size: 11px;
  font-weight: 900;
  line-height: 13.2px;
  text-decoration: none;
  text-transform: uppercase;
  padding: 6px 16px;
  border-radius: 14px;

  @media screen and (max-width: 600px) {
    background-color: #111;
    color: #fff;
    font-size: 16px;
    font-weight: 900;
    line-height: 13.2px;
    text-decoration: none;
    text-transform: uppercase;
    padding: 7px 20px;
    border-radius: 16px;
  }

  @media screen and (min-width: 601px) {
    padding: 7px 23px;
    font-size: 15px;
    border-radius: 16px;
  }
}
