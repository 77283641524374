.wrapper {
  width: 100%;
  border-radius: 20px;
  background: #fafafa;
  margin-bottom: 40px;
  position: relative;
  overflow: hidden;

  display: none;

  @media screen and (min-width: 600px) {
    display: block;
    height: 368px;
  }

  @media screen and (min-width: 1260px) {
    display: none;
  }

  img {
    width: 100%;
  }

  .largeImage {
    width: 186.24px;
  }

  .smallImage {
    width: 148.69px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
