* {
  box-sizing: border-box;
}

// Fix lineheight and prevent strange text scaling when changing orientations in mobile safari
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

// Remove default spacing around body in all browsers
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Render the main element consistently in IE.
main {
  display: block;
}

// Remove default spacing around lists
ul {
  padding: 0;
  margin: 0;
}

// Remove blue border around linked images in IE
img {
  border-style: none;
}

// Correct the inability to style clickable types in iOS and Safari.
button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

// 1. Change the font styles in all browsers.
// 2. Remove the margin in Firefox and Safari.
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; // 1
  font-size: 100%; // 1
  line-height: 1.15; // 1
  margin: 0; // 2
}

// Remove the inner border and padding in Firefox.
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

// Restore the focus styles unset by the previous rule.
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}
