.wrapper {
  width: 100%;
  border-radius: 20px;
  border: 1px solid #d4d8d9;
  background: #fff;
  color: #111;
  padding: 53px;
  margin-bottom: 40px;

  @media screen and (min-width: 600px) {
    height: 368px;
    padding: 40px 38px;
  }

  @media screen and (min-width: 960px) {
    padding: 40px;
    margin-bottom: 0;
  }

  @media screen and (min-width: 1260px) {
    margin-bottom: 0px;
    height: 360px;
  }

  p {
    font-size: 22px;
    font-weight: 900;
    line-height: 33px;
    margin-top: 0;

    @media screen and (min-width: 600px) {
      font-size: 18px;
      line-height: 31px;
      margin-bottom: 75px;
      // height: 186px;
    }

    @media screen and (min-width: 960px) {
      font-size: 20px;
      line-height: 150%;
      height: 186px;
    }

    @media screen and (min-width: 1260px) {
      height: 215px;
      margin-bottom: 22px;
    }
  }

  a {
    color: #096ffa;
    font-size: 24px;
    font-weight: 900;
    line-height: 26px;
    text-decoration: none;

    @media screen and (min-width: 600px) {
      font-size: 16px;
    }

    @media screen and (min-width: 960px) {
      font-size: 18px;
    }
  }
}
