.wrapper {
  width: 100%;
  border-radius: 20px;
  border: 1px solid #d4d8d9;
  background: #fff;
  color: #111;
  padding: 40px 40.5px;
  margin-bottom: 40px;

  @media screen and (min-width: 600px) {
    height: 368px;
    padding: 40px 38px;
  }

  @media screen and (min-width: 960px) {
    padding: 40px 40px 41px;
  }

  @media screen and (min-width: 1260px) {
    margin-bottom: 0px;
    height: 360px;
  }

  p:nth-child(1) {
    font-size: 18px;
    line-height: 27px;
    font-weight: 900;
    margin-bottom: 10px;

    @media screen and (min-width: 600px) {
      margin: 0 0 10px 0;
    }
    @media screen and (max-width: 1400px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media screen and (max-width: 1090px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media screen and (max-width: 990px) {
      font-size: 15px;
    }
    @media screen and (max-width: 599px) {
      font-size: 18px;
      line-height: 27px;
    }
  }

  p:nth-child(2) {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 15px;

    @media screen and (min-width: 600px) {
      margin: 0;
    }
    @media screen and (max-width: 1400px) {
      font-size: 15px;
      line-height: 26px;
    }
    @media screen and (max-width: 1260px) {
      font-size: 14px;
      line-height: 22px;
    }
    @media screen and (max-width: 1090px) {
      font-size: 14px;
      line-height: 22px;
    }
    @media screen and (max-width: 990px) {
      font-size: 14px;
      line-height: 20px;
    }
    @media screen and (max-width: 599px) {
      font-size: 16px;
      line-height: 26px;
    }
  }

  p:nth-child(3) {
    @media screen and (max-width: 1400px) {
      font-size: 15px;
    }
    @media screen and (max-width: 1260px) {
      font-size: 14px;
    }
    @media screen and (max-width: 1090px) {
      font-size: 14px;
    }
    @media screen and (max-width: 990px) {
      font-size: 14px;
    }
    @media screen and (max-width: 599px) {
      font-size: 16px;
    }
  }

  a {
    color: #096ffa;
    font-size: 16px;
    font-weight: 900;
    line-height: 26px;
  }
}
