.container {
  width: 250px;
  height: 500px;
  border: 1px solid #d4d8d9;
  margin-right: 30px;
  border-radius: 20px;

  @media screen and (min-width: 780px) {
    width: 280px;
  }

  .content {
    height: 162px;
    margin-bottom: 22px;
  }

  p {
    margin: 0 25px;
    max-width: 205px;
    font-size: 18px;
    font-weight: 900;
    line-height: 27px;
    color: #111;
    margin-bottom: 5px;
    white-space: pre-wrap;

    @media screen and (min-width: 780px) {
      max-width: 230px;
    }
  }

  .textContent {
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    white-space: normal;

    @media screen and (min-width: 780px) {
      -webkit-line-clamp: 5;
    }
  }

  .readLink {
    max-width: 205px;

    @media screen and (min-width: 780px) {
      max-width: 230px;
    }

    a {
      max-width: 205px;
      margin-left: 25px;
      color: #096ffa;
      font-size: 24px;
      font-weight: 900;
      line-height: 30px;
      text-decoration: none;

      @media screen and (min-width: 600px) {
        max-width: 230px;
        margin-left: 25px;
        color: #096ffa;
        font-size: 16px;
        font-weight: 900;
        line-height: 30px;
        text-decoration: none;
      }
    }
  }
}

.imageWrapper {
  width: 100%;
  overflow: hidden;
  border-radius: 20px 20px 0 0;

  img {
    height: 220px;
    margin-bottom: 25px;
  }
}
