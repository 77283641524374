.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1260px;
}

.footer-border-top {
  border-top: 1px solid #d4d8d9;
}

.footer-border-bottom {
  border-bottom: 1px solid #d4d8d9;
}

.row {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.column {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.brands {
  justify-content: space-between;
  padding: 30px 0px 30px 0px;
}

.brand-logo {
  width: 100px;
}

.brand-logo-ins {
  margin-right: 50px;
}

.site-logo-brands {
  padding-left: 45px;
}

.bottom-legal-copy,
.editions-list,
.copy-list-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.copy-list-group {
  color: #111;
  font-size: 11px;
  list-style: none;
  padding-left: 0;
  text-align: center;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.social-link {
  border: 1px solid #8ca8ac;
  border-radius: 50%;
  padding: 15px;
  display: inline-flex;
  height: 42px;
  margin: 0 12px;
  justify-content: center;
  align-items: center;
  width: 42px;
}

.social-link > div > img {
  width: 13px;
}

.facebook {
  height: 12px;
  transform: translateY(1px);
}

.instagram {
  transform: translateY(2px);
}

.app-badges-wrapper {
  display: flex;
  padding-right: 30px;
}

.app-badge:first-child {
  margin-right: 10px;
}

.app-badge {
  width: 109px;
  height: 32px;
}

.copy-link,
.editions-link {
  text-decoration: none;
  color: #111;

  &:hover {
    color: #111;
    text-decoration: underline;
  }
}

.copy-listgroup,
.editions-listgroup {
  list-style: none;
  font-size: 11px;
  padding-top: 1px;
  line-height: 2.82;
}

.copy-listitem,
.editions-listitem {
  display: inline-flex;
  border-left: 1px solid #d4d8d9;
  margin: 0 4px 4px 0;
  padding: 0 4px 0 11px;
  line-height: 9px;
}

.copy-first-listitem,
.editions-first-listitem {
  border-left: none;
  margin: 0 4px 4px 0;
  padding: 0 4px 0 11px;
}

.logo-pair-1 {
  height: 12px;
  margin-right: 12px;
  width: 92px;
}

.logo-pair-2 {
  width: 77px;
  height: 12px;
  margin-left: 12px;
}

.ca-privacy-copy {
  z-index: 2147483646;
}

.copyright {
  display: flex;
}

.bottom-legal-copy {
  padding-top: 25px;
}

.bottom-logo {
  height: 16px;
  padding-right: 25px;
}
.bottom-logo-two {
  height: 22px;
  padding-right: 25px;
}

.logo-pair-wrapper {
  position: relative;
  left: 22px;
}

.logo-pair-wrapper-two {
  padding: 40px 0;
  position: relative;
}

.editions-link-name {
  display: none;
}

.editions-listitem-label {
  margin-bottom: 5px;
}

.legal-copy {
  padding-bottom: 15px;
}

@media (max-width: 320px) {
  .social-link {
    margin: 0 8px;
  }
}

@media (max-width: 800px) {
  .copyright {
    flex-wrap: wrap;
  }

  .ca-privacy-copy {
    padding-top: 15px;
    display: inline-block;
    text-align: center;
    flex-direction: row;
  }

  .site-logo-brands {
    padding-left: 0;
    padding-bottom: 25px;
  }

  .brands {
    flex-direction: column;
  }

  .social-brands {
    padding-bottom: 25px;
  }

  .app-badges-wrapper {
    padding-right: 0;
  }
}

@media (max-width: 1024px) {
  .brands {
    flex-direction: column;
  }

  .copyright {
    flex-wrap: wrap;
  }

  .site-logo-brands {
    padding-left: 0;
    padding-bottom: 25px;
  }

  .social-brands {
    padding-bottom: 25px;
  }

  .app-badges-wrapper {
    padding-right: 0;
  }

  .copy-listgroup {
    display: block;
    width: 90%;
    text-align: center;
  }

  .editions-listitem-label {
    display: block;
    width: 100%;
    text-align: center;
  }

  .editions-listgroup {
    display: block;
    text-align: center;
  }
}
