@font-face {
  font-family: 'LabGrotesque';
  src: url('./fonts/LabGrotesque-Regular.woff2') format('woff2'),
    url('./fonts/LabGrotesque-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'LabGrotesque';
  src: url('./fonts/LabGrotesque-Black.woff2') format('woff2'),
    url('./fonts/LabGrotesque-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TiemposTextWeb';
  src: url('./fonts/TiemposTextWeb-Regular.woff2') format('woff2'),
    url('./fonts/TiemposTextWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TiemposTextWeb';
  src: url('./fonts/TiemposTextWeb-RegularItalic.woff2') format('woff2'),
    url('./fonts/TiemposTextWeb-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TiemposTextWeb';
  src: url('./fonts/TiemposTextWeb-Bold.woff2') format('woff2'),
    url('./fonts/TiemposTextWeb-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TiemposTextWeb';
  src: url('./fonts/TiemposTextWeb-BoldItalic.woff2') format('woff2'),
    url('./fonts/TiemposTextWeb-BoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: 'LabGrotesque', 'Arial', 'sans-serif';
  font-weight: 900;
}

p,
span,
div,
li,
a {
  font-family: 'LabGrotesque', 'Arial', 'sans-serif';
  font-weight: 400;
}

.font-stack {
  font-family: 'LabGrotesque', 'Arial', 'sans-serif';
}
