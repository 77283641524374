.wrapper {
  button:first-of-type {
    margin-right: 20px;
  }
}

.button {
  display: inline-grid;
  place-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: #fff;
  border: 1px solid #8ca8ac;
  cursor: pointer;
  transition: border-color 250ms ease-in-out, transform 150ms ease;

  &:hover {
    border-color: #096ffa;
  }

  &:active {
    transform: scale(0.95);
  }

  svg {
    pointer-events: none;
  }
}
