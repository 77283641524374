.quoteWrapper {
  text-align: center;
  margin-bottom: 65px;

  @media screen and (min-width: 600px) {
    margin-left: -60px;
    margin-right: -60px;
  }

  @media screen and (min-width: 960px) {
    margin-left: -110px;
    margin-right: -110px;
  }

  .quoteIcon {
    margin-top: 28px;
    width: 43px;
    height: auto;
  }
  .quoteText {
    font-family: 'LabGrotesque', sans-serif;
    font-weight: 900;
    font-size: 32px;
    line-height: 44px;
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .quoteAuthor {
    color: #6d787a;
    margin-bottom: 0;
  }
}

.contentWrapper {
  .prompt {
    font-weight: 900;
    font-size: 32px;
    line-height: 46px;
    letter-spacing: 0.5px;
  }

  .response {
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    line-height: 30px;
    font-family: 'TiemposTextWeb', serif;
    white-space: pre-wrap;

    @media screen and (max-width: 960px) {
      font-size: 18px;
      line-height: 160%;
    }
    @media screen and (max-width: 600px) {
      font-size: 16px;
      line-height: 165%;
    }
  }
}

.dividerWrapper {
  display: flex;
  justify-content: center;
  margin-top: 33px;
  margin-bottom: 40px;

  .divider {
    width: 100px;
    height: 6px;
    background-color: #dceaec;
  }
}

.closing {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  font-family: 'TiemposTextWeb', serif;

  @media screen and (max-width: 960px) {
    font-size: 18px;
    line-height: 160%;
  }
  @media screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 165%;
  }

  a {
    font-family: 'TiemposTextWeb', serif;

    color: #111;
    text-decoration-color: #111;
    text-decoration-line: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;
    transition: 0.2s;
    background-color: #0000;
  }
}
