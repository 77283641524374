.wrapper {
  background-color: #fafafa;
  width: 100%;

  img {
    -moz-border-radius: 25px; /* for Firefox */
    -webkit-border-radius: 25px; /* for Webkit-Browsers */
    border-radius: 25px; /* regular */
  }
}

.fullGridContent {
  width: 89.3%;
  margin: 0 auto;
  padding-bottom: 73px;
  padding-top: 50px;

  div:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: 600px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    max-width: 570px;
    padding-bottom: 70px;
  }

  @media screen and (min-width: 960px) {
    display: none;
  }

  h2 {
    color: #111;
    font-size: 28px;
    font-weight: 900;
    line-height: 33.6px;
    margin: 0 0 16px;

    @media screen and (min-width: 600px) {
      font-size: 32px;
      line-height: 38px;
    }
  }
}

.desc {
  color: #111;
  font-size: 16px;
  line-height: 26px;
  margin: 0;
  padding-bottom: 45px;

  @media screen and (min-width: 600px) {
    padding-bottom: 0;
  }
}

.textContainer {
  @media screen and (min-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 368px;
  }

  @media screen and (min-width: 1260px) {
    display: block;
  }

  h2 {
    margin-top: 0;
  }
}

.splitGridContent {
  display: none;

  @media screen and (min-width: 960px) {
    display: flex;
    justify-content: flex-end;
    width: 89.3%;
    margin: 0 auto;
    max-width: 1230px;
    height: auto;
    padding-bottom: 150px;
    padding-top: 105px;
  }

  .rightColumn {
    margin-left: 50px;
    max-width: 275px;

    @media screen and (min-width: 1260px) {
      margin-left: 75px;
      max-width: 360px;
    }
  }

  .textContainer {
    width: 275px;
    height: auto;
    margin-bottom: 110px;

    @media screen and (min-width: 1260px) {
      width: 360px;
    }
  }

  .imageStack {
    position: relative;
    height: auto;
    width: 360px;

    .imageOne {
      width: 184px;

      @media screen and (min-width: 1260px) {
        width: 253px;
      }
    }

    .imageTwo {
      width: 150px;
      position: absolute;
      bottom: -128px;
      left: 154px;

      @media screen and (min-width: 1260px) {
        width: 202px;
      }
    }
  }

  .desktopGrid {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 75px;
    row-gap: 75px;
  }
}
