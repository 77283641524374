.wrapper {
  width: 89.3%;
  margin: 0 auto;

  @media screen and (min-width: 600px) {
    max-width: 570px;
  }

  @media screen and (min-width: 960px) {
    width: 930px;
    display: flex;
    justify-content: center;
  }

  @media screen and (min-width: 1260px) {
    width: 1230px;
  }
}

.textContent {
  margin-top: 44px;

  @media screen and (min-width: 960px) {
    position: relative;
    left: 25px;
    margin-top: 124px;
  }

  @media screen and (min-width: 1260px) {
    left: -44px;
  }

  p {
    font-size: 11px;
    font-weight: 900;
    line-height: 13.2px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    color: #096ffa;
    margin: 0 0 12px;
  }

  h1 {
    margin: 0 0 30px;
    font-size: 28px;
    font-weight: 900;
    line-height: 33.6px;
    color: #111;

    @media screen and (min-width: 600px) {
      font-size: 32px;
      line-height: 38.4px;
    }
  }
}
