.wrapper {
  width: 100%;
  border-radius: 20px;
  border: 1px solid #d4d8d9;
  background: #fff;
  color: #111;
  margin-bottom: 40px;

  @media screen and (min-width: 600px) {
    height: 368px;
  }

  @media screen and (min-width: 1260px) {
    margin-bottom: 0px;
    height: 360px;
  }

  img {
    width: 100%;
  }

  .imgBorders {
    -moz-border-radius: 20px 20px 0px 0px; /* for Firefox */
    -webkit-border-radius: 20px 20px 0px 0px; /* for Webkit-Browsers */
    border-radius: 20px 20px 0px 0px; /* regular */
  }

  p:nth-child(1) {
    margin: 0 0 15px;
    font-size: 18px;
    font-weight: 900;
    line-height: 27px;
  }

  a {
    color: #096ffa;
    font-size: 16px;
    font-weight: 900;
    line-height: 26px;
  }
}

.textContent {
  padding: 15px 41.5px 40px;

  @media screen and (min-width: 600px) {
    padding: 20px 39px 29px;
  }

  .author {
    @media screen and (max-width: 1400px) {
      font-size: 15px;
    }
    @media screen and (max-width: 1260px) {
      font-size: 14px;
    }
    @media screen and (max-width: 1090px) {
      font-size: 14px;
    }
    @media screen and (max-width: 990px) {
      font-size: 14px;
    }
    @media screen and (max-width: 599px) {
      font-size: 16px;
    }
  }
}
