.masthead {
  display: flex;
  justify-content: space-between;
  flex-basis: auto;
  height: 74px;
  margin: 0 auto;
  border-bottom: 1px solid #d4d8d9;

  .left-align-div {
    display: flex;
    justify-content: flex-start;
    width: 34.4%;
  }

  .center-align-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 31.2%;
  }

  .right-align-div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 34.4%;

    .open-roles-link {
      background-color: #096ffa;
      color: #fff;
      font-size: 14px;
      font-weight: 900;
      line-height: 1px;
      height: 30px;
      text-decoration: none;
      text-align: center;
      padding: 20px 12px;
      margin-right: 20px;
      border-radius: 14px;

      @media screen and (max-width: 600px) {
        padding: 14px 17px;
        font-size: 13px;
        border-radius: 16px;
        line-height: 1px;
      }

      @media screen and (max-width: 450px) {
        padding: 12px 14px;
        font-size: 12px;
        border-radius: 16px;
        line-height: 6px;
      }
    }
  }

  .logo {
    height: 18px;
    width: 117px;
  }

  @media screen and (min-width: 600px) {
    max-width: 570px;

    .logo {
      height: 25px;
      width: 170px;
    }

    .right-align-div {
      .open-roles-link {
        margin-right: 0px;
        font-size: 14px;
        height: 25px;
        padding: 15px 26px;
        border-radius: 16px;
      }
    }
  }

  @media screen and (min-width: 960px) {
    max-width: 930px;
  }

  @media screen and (min-width: 1260px) {
    max-width: 1230px;
  }
}
