.wrapper {
  .title {
    font-weight: 900;
    text-align: center;
    margin-top: 32px;
    margin-bottom: 24px;

    @media screen and (min-width: 600px) {
      font-size: 40px;
      line-height: 44px;
      letter-spacing: 0.12px;
      padding: 0 20px;
      max-width: 83.33%;
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (min-width: 960px) {
      font-size: 80px;
      line-height: 92px;
      letter-spacing: 2.4px;
    }

    @media screen and (max-width: 599px) {
      font-size: 30px;
      line-height: 33px;
      letter-spacing: 0.9px;
      padding: 0 20px;
    }
  }

  .titleImg {
    top: 208px;
    width: 100%;
  }

  .infomationSection {
    text-align: center;
    margin-bottom: 32px;

    .insiderBubble {
      display: flex;
      justify-content: center;
      margin-top: 40px;

      p {
        width: 64px;
        height: 64px;
        background-color: #096ffa;
        border-radius: 100px;
        text-transform: uppercase;
        color: white;
        // padding-top: 28px;
        padding-left: 3.5px;
        font-size: 11px;
        font-weight: 500;
        letter-spacing: 1.5px;
        line-height: 64px;
        margin-bottom: 0;
      }
    }

    .author {
      text-align: center;
      font-size: 16px;
      font-weight: 900;
      line-height: 19px;
      margin-bottom: 8px;
    }

    .pubDate {
      color: #6d787a;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      margin-top: 0;
    }
  }

  .dropCap,
  .authorIntro {
    font-family: 'TiemposTextWeb', serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 33px;
    margin-right: 400px;
    margin-left: 400px;

    @media screen and (min-width: 600px) {
      width: 480px;
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (min-width: 960px) {
      width: 640px;
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (max-width: 599px) {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  .dropCap::first-letter {
    box-shadow: 0 4px 0 #096ffa;
    float: left;
    font-size: 89px;
    font-weight: 900;
    line-height: 61px;
    margin-right: 16px;
    margin-top: 11px;
    padding-bottom: 10px;
    font-family: 'LabGrotesque', sans-serif;
  }

  .dividerWrapper {
    display: flex;
    justify-content: center;
    margin-top: 48px;
    margin-bottom: 48px;

    .divider {
      width: 100px;
      height: 6px;
      background-color: #dceaec;
    }
  }

  .content {
    @media screen and (min-width: 600px) {
      width: 480px;
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (min-width: 960px) {
      width: 640px;
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (max-width: 599px) {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
}
