.wrapper {
  background-color: #fafafa;

  &::before {
    content: '';
    display: block;
    background-color: #fafafa;
    height: 146px;

    @media screen and (min-width: 600px) {
      height: 120px;
    }

    @media screen and (min-width: 960px) {
      height: 70px;
    }

    @media screen and (min-width: 1260px) {
      height: 105px;
    }
  }

  img {
    -moz-border-radius: 25px; /* for Firefox */
    -webkit-border-radius: 25px; /* for Webkit-Browsers */
    border-radius: 25px; /* regular */
  }
}

.fullGridContent {
  width: 89.3%;
  margin: 0 auto;
  padding-bottom: 73px;

  div:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: 600px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    max-width: 570px;
    padding-bottom: 70px;
  }

  @media screen and (min-width: 960px) {
    max-width: 977px;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 49px;
    padding-bottom: 150px;
  }

  @media screen and (min-width: 1260px) {
    display: none;
  }

  h2 {
    color: #111;
    font-size: 28px;
    font-weight: 900;
    line-height: 33.6px;
    margin: 0 0 16px;

    @media screen and (min-width: 960px) {
      font-size: 32px;
      line-height: 38px;
    }
  }
}

.desc {
  color: #111;
  font-size: 16px;
  line-height: 26px;
  margin: 0 0 16px 0;
}

.textContainer {
  @media screen and (min-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 368px;
  }

  @media screen and (min-width: 1260px) {
    display: block;
  }
}

.roundedLink {
  display: inline-block;
  margin-bottom: 49px;
  padding: 9px 16px;

  @media screen and (min-width: 600px) {
    margin-bottom: 0px;
    padding: 9px 23px;
    font-size: 15px;
    line-height: 14.2px;
  }
}

.splitGridContent {
  display: none;

  @media screen and (min-width: 1260px) {
    display: flex;
    justify-content: flex-start;
    width: 89.3%;
    margin: 0 auto;
    max-width: 1230px;
    height: auto;
    padding-bottom: 150px;
  }

  .textContainer {
    width: 360px;
    height: auto;
    margin-bottom: 69px;
  }

  .imageStack {
    position: relative;
    height: auto;
    width: 360px;

    .imageOne {
      width: 253px;
    }

    .imageTwo {
      width: 202px;
      position: absolute;
      bottom: -128px;
      left: 154px;
    }
  }

  .desktopGrid {
    width: 100%;
    height: 100%;
    margin-left: 75px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 75px;
    row-gap: 75px;
  }
}
